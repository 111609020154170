export const miscellaneousInventoryConstants = {
    //Crear
    MISCELLANEOUS_INVENTORY_CREATE_REQUEST: 'MISCELLANEOUS_INVENTORY_CREATE_REQUEST',
    MISCELLANEOUS_INVENTORY_CREATE_SUCCESS: 'MISCELLANEOUS_INVENTORY_CREATE_SUCCESS',
    MISCELLANEOUS_INVENTORY_CREATE_FAILURE: 'MISCELLANEOUS_INVENTORY_CREATE_FAILURE',

    MISCELLANEOUS_INVENTORY_GET_REQUEST: 'MISCELLANEOUS_INVENTORY_GET_REQUEST',
    MISCELLANEOUS_INVENTORY_GET_SUCCESS: 'MISCELLANEOUS_INVENTORY_GET_SUCCESS',
    MISCELLANEOUS_INVENTORY_GET_FAILURE: 'MISCELLANEOUS_INVENTORY_GET_FAILURE',

    MISCELLANEOUS_INVENTORY_UPDATE_REQUEST: 'MISCELLANEOUS_INVENTORY_UPDATE_REQUEST',
    MISCELLANEOUS_INVENTORY_UPDATE_SUCCESS: 'MISCELLANEOUS_INVENTORY_UPDATE_SUCCESS',
    MISCELLANEOUS_INVENTORY_UPDATE_FAILURE: 'MISCELLANEOUS_INVENTORY_UPDATE_FAILURE',

    MISCELLANEOUS_INVENTORY_DELETE_REQUEST: 'MISCELLANEOUS_INVENTORY_DELETE_REQUEST',
    MISCELLANEOUS_INVENTORY_DELETE_SUCCESS: 'MISCELLANEOUS_INVENTORY_DELETE_SUCCESS',
    MISCELLANEOUS_INVENTORY_DELETE_FAILURE: 'MISCELLANEOUS_INVENTORY_DELETE_FAILURE',

    MISCELLANEOUS_INVENTORY_TABLE_REQUEST: 'MISCELLANEOUS_INVENTORY_TABLE_REQUEST',
    MISCELLANEOUS_INVENTORY_TABLE_SUCCESS: 'MISCELLANEOUS_INVENTORY_TABLE_SUCCESS',
    MISCELLANEOUS_INVENTORY_TABLE_FAILURE: 'MISCELLANEOUS_INVENTORY_TABLE_FAILURE',

    MISCELLANEOUS_INVENTORY_HISTORY_REQUEST: 'MISCELLANEOUS_INVENTORY_HISTORY_REQUEST',
    MISCELLANEOUS_INVENTORY_HISTORY_SUCCESS: 'MISCELLANEOUS_INVENTORY_HISTORY_SUCCESS',
    MISCELLANEOUS_INVENTORY_HISTORY_FAILURE: 'MISCELLANEOUS_INVENTORY_HISTORY_FAILURE',
};
