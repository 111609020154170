export const assetsConstants = {

    REGISTER_REQUEST: 'ASSETS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'ASSETS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'ASSETS_REGISTER_FAILURE',

    DELETE_REQUEST: 'ASSETS_DELETE_REQUEST',
    DELETE_SUCCESS: 'ASSETS_DELETE_SUCCESS',
    DELETE_FAILURE: 'ASSETS_DELETE_FAILURE',

    RESTORE_REQUEST: 'ASSETS_RESTORE_REQUEST',
    RESTORE_SUCCESS: 'ASSETS_RESTORE_SUCCESS',
    RESTORE_FAILURE: 'ASSETS_RESTORE_FAILURE',
    
    UPDATE_DATA_REQUEST: 'ASSETS_UPDATE_DATA_REQUEST',
    UPDATE_DATA_SUCCESS: 'ASSETS_UPDATE_DATA_SUCCESS',
    UPDATE_DATA_FAILURE: 'ASSETS_UPDATE_DATA_FAILURE',

    ASSETS_TABLE_REQUEST: 'ASSETS_TABLE_REQUEST',
    ASSETS_TABLE_SUCCESS: 'ASSETS_TABLE_SUCCESS',
    ASSETS_TABLE_FAILURE: 'ASSETS_TABLE_FAILURE',

    ASSETS_GET_REQUEST: 'ASSETS_GET_REQUEST',
    ASSETS_GET_SUCCESS: 'ASSETS_GET_SUCCESS',
    ASSETS_GET_FAILURE: 'ASSETS_GET_FAILURE',

    ASSETS_UPDATE_REQUEST: 'ASSETS_UPDATE_REQUEST',
    ASSETS_UPDATE_SUCCESS: 'ASSETS_UPDATE_SUCCESS',
    ASSETS_UPDATE_FAILURE: 'ASSETS_UPDATE_FAILURE',

};
