export const terminalConstants = {
    //Crear
    TERMINAL_CREATE_REQUEST: 'TERMINAL_CREATE_REQUEST',
    TERMINAL_CREATE_SUCCESS: 'TERMINAL_CREATE_SUCCESS',
    TERMINAL_CREATE_FAILURE: 'TERMINAL_CREATE_FAILURE',

    TERMINAL_GET_REQUEST: 'TERMINAL_GET_REQUEST',
    TERMINAL_GET_SUCCESS: 'TERMINAL_GET_SUCCESS',
    TERMINAL_GET_FAILURE: 'TERMINAL_GET_FAILURE',

    TERMINAL_UPDATE_REQUEST: 'TERMINAL_UPDATE_REQUEST',
    TERMINAL_UPDATE_SUCCESS: 'TERMINAL_UPDATE_SUCCESS',
    TERMINAL_UPDATE_FAILURE: 'TERMINAL_UPDATE_FAILURE',

    TERMINAL_TABLE_REQUEST: 'TERMINAL_TABLE_REQUEST',
    TERMINAL_TABLE_SUCCESS: 'TERMINAL_TABLE_SUCCESS',
    TERMINAL_TABLE_FAILURE: 'TERMINAL_TABLE_FAILURE',

    TERMINAL_SELECT_REQUEST: 'TERMINAL_SELECT_REQUEST',
    TERMINAL_SELECT_SUCCESS: 'TERMINAL_SELECT_SUCCESS',
    TERMINAL_SELECT_FAILURE: 'TERMINAL_SELECT_FAILURE',

    TERMINAL_RECORDS_REQUEST: 'TERMINAL_RECORDS_REQUEST',
    TERMINAL_RECORDS_SUCCESS: 'TERMINAL_RECORDS_SUCCESS',
    TERMINAL_RECORDS_FAILURE: 'TERMINAL_RECORDS_FAILURE',

};
