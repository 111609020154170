export const miscellaneousConstants = {
    //Crear
    MISCELLANEOUS_CREATE_REQUEST: 'MISCELLANEOUS_CREATE_REQUEST',
    MISCELLANEOUS_CREATE_SUCCESS: 'MISCELLANEOUS_CREATE_SUCCESS',
    MISCELLANEOUS_CREATE_FAILURE: 'MISCELLANEOUS_CREATE_FAILURE',

    MISCELLANEOUS_GET_REQUEST: 'MISCELLANEOUS_GET_REQUEST',
    MISCELLANEOUS_GET_SUCCESS: 'MISCELLANEOUS_GET_SUCCESS',
    MISCELLANEOUS_GET_FAILURE: 'MISCELLANEOUS_GET_FAILURE',

    MISCELLANEOUS_UPDATE_REQUEST: 'MISCELLANEOUS_UPDATE_REQUEST',
    MISCELLANEOUS_UPDATE_SUCCESS: 'MISCELLANEOUS_UPDATE_SUCCESS',
    MISCELLANEOUS_UPDATE_FAILURE: 'MISCELLANEOUS_UPDATE_FAILURE',

    MISCELLANEOUS_TABLE_REQUEST: 'MISCELLANEOUS_TABLE_REQUEST',
    MISCELLANEOUS_TABLE_SUCCESS: 'MISCELLANEOUS_TABLE_SUCCESS',
    MISCELLANEOUS_TABLE_FAILURE: 'MISCELLANEOUS_TABLE_FAILURE',

    MISCELLANEOUS_SELECT_REQUEST: 'MISCELLANEOUS_SELECT_REQUEST',
    MISCELLANEOUS_SELECT_SUCCESS: 'MISCELLANEOUS_SELECT_SUCCESS',
    MISCELLANEOUS_SELECT_FAILURE: 'MISCELLANEOUS_SELECT_FAILURE',

    MISCELLANEOUS_OFFER_REQUEST: 'MISCELLANEOUS_OFFER_REQUEST',
    MISCELLANEOUS_OFFER_SUCCESS: 'MISCELLANEOUS_OFFER_SUCCESS',
    MISCELLANEOUS_OFFER_FAILURE: 'MISCELLANEOUS_OFFER_FAILURE',

};
