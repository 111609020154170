export const authorizationCodeConstants = {

    REGISTER_REQUEST: 'AUTHORIZATION_CODE_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'AUTHORIZATION_CODE_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'AUTHORIZATION_CODE_REGISTER_FAILURE',

    DELETE_REQUEST: 'AUTHORIZATION_CODE_DELETE_REQUEST',
    DELETE_SUCCESS: 'AUTHORIZATION_CODE_DELETE_SUCCESS',
    DELETE_FAILURE: 'AUTHORIZATION_CODE_DELETE_FAILURE',

    TABLE_REQUEST: 'AUTHORIZATION_CODE_TABLE_REQUEST',
    TABLE_SUCCESS: 'AUTHORIZATION_CODE_TABLE_SUCCESS',
    TABLE_FAILURE: 'AUTHORIZATION_CODE_TABLE_FAILURE',
    
    CODE_REQUEST: 'AUTHORIZATION_CODE_CODE_REQUEST',
    CODE_SUCCESS: 'AUTHORIZATION_CODE_CODE_SUCCESS',
    CODE_FAILURE: 'AUTHORIZATION_CODE_CODE_FAILURE',
};
