export const resguardConstants = {

    RESGUARD_ADD_REQUEST: 'RESGUARD_ADD_REQUEST',
    RESGUARD_ADD_SUCCESS: 'RESGUARD_ADD_SUCCESS',
    RESGUARD_ADD_FAILURE: 'RESGUARD_ADD_FAILURE',

    RESGUARD_WITHDRAWAL_REQUEST: 'RESGUARD_WITHDRAWAL_REQUEST',
    RESGUARD_WITHDRAWAL_SUCCESS: 'RESGUARD_WITHDRAWAL_SUCCESS',
    RESGUARD_WITHDRAWAL_FAILURE: 'RESGUARD_WITHDRAWAL_FAILURE',

    RESGUARD_REPORT_REQUEST: 'RESGUARD_REPORT_REQUEST',
    RESGUARD_REPORT_SUCCESS: 'RESGUARD_REPORT_SUCCESS',
    RESGUARD_REPORT_FAILURE: 'RESGUARD_REPORT_FAILURE',

    RESGUARD_DATATABLE_REQUEST: 'RESGUARD_DATATABLE_REQUEST',
    RESGUARD_DATATABLE_SUCCESS: 'RESGUARD_DATATABLE_SUCCESS',
    RESGUARD_DATATABLE_FAILURE: 'RESGUARD_DATATABLE_FAILURE',

    RESGUARD_GET_REQUEST: 'RESGUARD_GET_REQUEST',
    RESGUARD_GET_SUCCESS: 'RESGUARD_GET_SUCCESS',
    RESGUARD_GET_FAILURE: 'RESGUARD_GET_FAILURE',
    
    RESGUARD_CONFIRM_REQUEST: 'RESGUARD_CONFIRM_REQUEST',
    RESGUARD_CONFIRM_SUCCESS: 'RESGUARD_CONFIRM_SUCCESS',
    RESGUARD_CONFIRM_FAILURE: 'RESGUARD_CONFIRM_FAILURE',
};
