/**
 * Pesos calculados de bolsa para determinados productos por código
 */
export const WeightProduct = [

     //QUITAMOS LA RESTICCION PARA EL TOCINO AHUMADO****
    // {
       // code: 96, weight:
    //},
    

    //AGREGAMOS LOS MINIMOS PARA LOS COMBOS
    //*****************


    {
        code: 'c1', weight:0.500
    },
    {
        code: 'C1', weight:0.500
    },
    {
        code: 'C2', weight:0.500
    },
    {
        code: 'c2', weight:0.500
    },
    {
        code: 'c3', weight:0.650
    },
    {
        code: 'C3', weight:0.650
    },
    {
        code: 'c4', weight:0.650
    },
    {
        code: 'C4', weight:0.650
    },  
    {
        code: 'c5', weight:0.500
    },
    {
        code: 'C5', weight:0.500
    },
    {
        code: 'c6', weight:0.650
    },
    {
        code: 'C6', weight:0.650
    },
    {
        code: 'c8', weight:0.500
    },
    {
        code: 'C8', weight:0.500
    },



// productos convencionales:



    //CHICHARRON
     {
        code: 33, weight:0.070
    },
    //MORATADELA DE 450GR ******
    {
        code: 69, weight:0.450 
    },
    //MORTADELA 900GR J KING  *****
    {
        code: 84, weight:0.900 
    },
    //MANTECA
    {
        code: 34, weight:0.680
    },

      //YOGURT
    {
        code: 77, weight:0.520
    },
      //YOGURT DE SABOR
      {
        code: 92, weight:0.520
    },

       //HUEVOS
       {
        code: 28, weight:0.065
    },


       //QUESO DE MANO
    {
        code: 8, weight:0.640
    },

         //QUESO GUAYANES
    {
        code: 39, weight:0.640
    },

    //CREMA DE LECHES TINAS 250GR
    {
       code: 64, weight:0.450
    },
    //CREMA DE LECHES TINAS 500GR
    {
       code: 95, weight:0.250
    },
   
    
     //DELIVERY
     {
       code: 'D1', weight:0
     },
     //DELIVERY
     {
       code: 'd1', weight:0
     }
];